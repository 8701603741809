import {Margins, Content, TableCell} from 'pdfmake/interfaces';
import moment from 'moment';
import htmlToPdfmake from 'html-to-pdfmake'
import { HTTP_REQUEST_TYPE, PORT_RUNWAY_API } from "../../constants/api";
import { sendHttpRequest } from "../api";
import { IPortPageRunwaySave } from '../../slice/portPageRunwaySlice';
import { SEC_TITLES } from '../../constants/portPageRunwayConstant';
import COLORS from '../../constants/color';
import { subHeaderLayout, containerLayout, secTitleLayout, secContentLayout } from '../../components/PDFGen/PDFGenTableLayout';
import defaultNewRunwayObjectTemplate from './PortPageRunwayNewTemplate.json';

const getRunways = async (runwaySearch?) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        PORT_RUNWAY_API.GET_RUNWAY_LIST,
        runwaySearch ? runwaySearch : {}
    );
    return resp;
};

const postRunwaySave = async (addRunwayRequest: IPortPageRunwaySave) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        PORT_RUNWAY_API.POST_RUNWAY_SAVE,
        {
            ...addRunwayRequest
        }
    );
    return resp;
};

const getRunwayPDF = async (downloadPDFRequest) => {
    let requestBody = {
        ...downloadPDFRequest,
        responseType: 'blob'
    }

    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        PORT_RUNWAY_API.GET_RUNWAY_PDF,
        requestBody
    );
    return resp;
};

const _isObject = value => {
    return typeof value === 'object' && value !== null && Object.keys(value).length > 0;
}

const _clearData = (dataSource, fieldsNeedKeep, fieldsNeedDelete) => {
    if (Array.isArray(dataSource) && dataSource.length > 0) {
        let resultArr: Array<object> = [];

        dataSource.forEach(obj => {
            if (_isObject(obj)) {
                resultArr.push(_clearData(obj, fieldsNeedKeep, fieldsNeedDelete) || {});
            }
        });

        return resultArr;
    } else if (_isObject(dataSource)) {
        let tempObj = {};
        Object.keys(dataSource).forEach(key => {
            let tempVal = dataSource[key];
            if (typeof tempVal === 'string' || typeof tempVal === 'number' || typeof tempVal === 'boolean') {
                if (!fieldsNeedDelete.includes(key))
                    tempObj[key] = fieldsNeedKeep.includes(key) ? tempVal : '';
            } else if (Array.isArray(tempVal) && tempVal.length > 0) {// eg. info:[]
                tempObj[key] = _clearData(tempVal, fieldsNeedKeep, fieldsNeedDelete);
            } else if (_isObject(tempVal)) {// eg. takeoff: {lights:[],performance:[]}
                tempObj[key] = _clearData(tempVal, fieldsNeedKeep, fieldsNeedDelete);
            }
        });

        return tempObj;
    }
}

const getCleanRunwayObj = runwayDataObj => {
    const fieldsNeedKeep = ['airline', 'icao', 'airportName', 'type', 'subType', 'desc', 'aircraftType'];
    const fieldsNeedDelete = ['_id', 'version', 'created', '__v', 'userName', 'uuid'];
    const cleanObj = _clearData(runwayDataObj, fieldsNeedKeep, fieldsNeedDelete);
    return cleanObj;
}

const getFixedValueColumns = (secTitle) => {
    let fixedCols: Array<string> = [];
    if (secTitle === SEC_TITLES.lvoApproach) {
        fixedCols.push('type');
        fixedCols.push('subType');
    } else if (secTitle === SEC_TITLES.takeOff_Performance)
        fixedCols.push('type');

    return fixedCols;
}

const _getDynamicColNamesFromRawData = (secDataArray) => {
    let cols: string[] = [];
    secDataArray && secDataArray.forEach(row => {
        row && row.info && row.info.forEach(colKey => {
            if (colKey.desc && !cols.includes(colKey.desc))
                cols.push(colKey.desc)
            if (colKey.aircraftType && !cols.includes(colKey.aircraftType))
                cols.push(colKey.aircraftType)
            if (colKey.subType && !cols.includes(colKey.subType))
                cols.push(colKey.subType)
        });
    });
    return cols;
}

//gen table with columns like 'type','subType','RA'...
const _convertRawData2List = (rawData, colNames, secTitle, isEditMode) => {
    const rawSafeData = rawData || [];
    const list = rawSafeData.map(row => {
        var tempObj = {};
        if (!colNames.includes('type'))
            tempObj['type'] = row.type;
        if (secTitle === SEC_TITLES.lvoApproach)
            tempObj['subType'] = row.subType;
        colNames.forEach(col => {
            tempObj[col] = _getValueByKey(row.info, col, secTitle, isEditMode)
        })
        return tempObj;
    })
    return list;
}

const _getValueByKey = (arrayIn, key, secTitle, isEditMode) => {
    const defaultVal = isEditMode ? '' : '--';
    let matchObj;
    let resultValue = '';
    let itemKey = '';
    try {
        if (arrayIn) {
            switch (secTitle) {
                case SEC_TITLES.lvoApproach:
                case SEC_TITLES.takeOff_Lights:
                    itemKey = 'desc';
                    break;
                case SEC_TITLES.approach:
                    itemKey = 'aircraftType';
                    break;
                case SEC_TITLES.takeOff_Performance:
                    itemKey = 'subType';
                    break;
                default:
                    itemKey = 'desc';
                    break;
            }

            matchObj = arrayIn.find(item => item[itemKey] === key);
        }

        if (secTitle === SEC_TITLES.approach && matchObj && matchObj.value)
            resultValue = matchObj.value.toString().toUpperCase() === 'YES' ? 'Yes' : (matchObj.value.toString().toUpperCase() === 'NO' ? 'No' : matchObj.value);
        else
            resultValue = matchObj && matchObj.value ? matchObj.value : defaultVal;

        return resultValue;
    } catch (error) {
        console.log('getValueByKey - secTitle=' + secTitle + '; arrayIn=' + arrayIn + '; error=' + error);
        return defaultVal;
    }
}

const getConvertedSecData = (secTitle, rawData, isEditMode) => {
    const columns = _getDynamicColNamesFromRawData(rawData);
    const items = _convertRawData2List(rawData, columns, secTitle, isEditMode);
    return items;
}

const convertItemToRawDataFormat = (itemObj, secTitle) => {
    let convertObj = {};
    let convertObj_InfoArray: Array<object> = [];
    const infoItemKey = secTitle === SEC_TITLES.approach ? 'aircraftType' : 'desc';

    Object.keys(itemObj).forEach(key => {
        let tempInfoItem = {};
        if (key === 'type')
            convertObj[key] = itemObj[key];
        else {
            tempInfoItem[infoItemKey] = key;
            tempInfoItem['value'] = itemObj[key];

            convertObj_InfoArray.push(tempInfoItem);
        }
    });

    convertObj['info'] = convertObj_InfoArray;
    return convertObj;
}

const getDefaultNewRunwayObject = (params) => {
    let defaultNewRunwayObject = { ...defaultNewRunwayObjectTemplate };
    defaultNewRunwayObject.airline = params.airline;
    defaultNewRunwayObject.icao = params.icao;
    defaultNewRunwayObject.airportName = params.icao;
    defaultNewRunwayObject.runway = '01L';
    return defaultNewRunwayObject;
}

//#region gen PDF Content
const _getHeaderContent = (runwayDataObj) => {
    return {
        columns: [
            {
                text: runwayDataObj.icao,
                style: 'headerLeft'
            },
            {
                text: `${runwayDataObj.cityName}, ${runwayDataObj.countryName}`,
                style: 'headerRight'
            }
        ]
    } as Content;
}

const _getSubHeaderContent = (runwayDataObj) => {
    const dateLastUpdate = moment.utc(new Date(runwayDataObj.created)).format("DD MMM YYYY");

    return {
        columns: [
            {
                width: '35%',
                text: runwayDataObj.airportName,
                style: 'subHeaderL'
            },
            {
                width: 'auto',
                text: [
                    { text: `Rev ${runwayDataObj.version} (${dateLastUpdate})`, style: 'subHeader' },//Rev 8888，fixed for now, pending to do
                    { text: ` for ${runwayDataObj.airline}`, style: 'subHeaderMR' }
                ],
            },
            {
                width: '*',
                text: '',
            },
            [
                _getSubHeaderDarkBGContent(subHeaderLayout, runwayDataObj.runway, 'subHeaderR')
            ]
        ],
        columnGap: 10
    } as Content;
}

const _getSubHeaderDarkBGContent = (layout, textVal, styleName) => {
    return {
        layout,
        table: {
            widths: ['*', 'auto'],
            body: [
                [{}, { text: textVal, fillColor: COLORS.black, style: styleName }]
            ]
        }
    } as Content;
}

const _getSecTitleContent = (secTitle, styleName = 'secTitle') => {
    return [
        {
            border: [true, true, true, false],
            layout: secTitleLayout,
            table: {
                widths: ['auto'],
                body: [
                    [{ text: secTitle, fillColor: COLORS.black, style: styleName }]
                ]
            }
        }
    ] as TableCell;
}

const _getSecTextContent = (textVal) => {
    return [{
        border: [true, false, true, false],
        text: [{ text: textVal, style: 'secContentBase' }],
        margin: [4, 4]
    }] as TableCell;
}

const _getSecItemsContentHeader = (columns, fixedCols) => {
    return columns.map((col, index) => {
        if (index === 0) {
            return { colSpan: fixedCols.length, text: '' }
        }

        return { text: fixedCols.includes(col) ? '' : col, style: 'secContentItems_Header' }
    }) as Content;
}

const _getSecItemsContentBody = (columns, fixedCols, dataIn, secTitle) => {
    return dataIn.map(item => {
        return columns.map(col => {
            return {
                text: fixedCols.includes(col) ? item[col] : _getValueByKey(item?.info, col, secTitle, false),
                style: fixedCols.length > 1 && col === 'type' ? 'secContentItems_ColType' : ''
            }
        })
    });
}

interface IGetSecItemsContentParams {
    dataIn: Array<object>,
    secTitle: string,
    fixedCols?: string[],
    margin?: Margins,
    tableWidth?: string,
}

interface IContentSecsShowObj {
    notes: boolean,
    lvoApproach: boolean,
    approach: boolean,
    takeOff: boolean,
    engineFailure: boolean
};

const _getSecItemsContent = ({ dataIn, fixedCols = ['type'], secTitle, margin = [4, 4, 4, 4], tableWidth = '100%' }: IGetSecItemsContentParams) => {
    let columns: Array<string> = [];
    columns.push(...fixedCols);
    const dynamicCols = _getDynamicColNamesFromRawData(dataIn);
    columns.push(...dynamicCols);

    let colWidths:Array<string> = [];
    if ([SEC_TITLES.takeOff_Lights, SEC_TITLES.takeOff_Performance].includes(secTitle)) {
        colWidths = new Array(columns.length).fill((90 / columns.length) + '%');
        colWidths[0] = ((90 / columns.length) + 10) + '%';
    } else {
        colWidths = new Array(columns.length).fill('*');
    }

    return [
        {
            width: tableWidth,
            border: [true, false, true, false],
            layout: secContentLayout,
            style: 'secContentItems',
            table: {
                widths: colWidths,
                body: [
                    _getSecItemsContentHeader(columns, fixedCols),
                    ..._getSecItemsContentBody(columns, fixedCols, dataIn, secTitle),
                ]
            },
            margin: margin
        } as Content
    ];
}

const _getSecContentRemarksContent = (remarksVal) => {
    return [{
        border: [true, false, true, false],
        text: [
            { text: 'Remarks\n', style: 'secContentRemarks_Title' },
            { text: remarksVal, style: 'secContentRemarks_Content' },
        ],
        margin: [4, 0]
    }] as TableCell;
}

const _getSecContentTakeoffContent = (runwayDataObj) => {
    const lightsItemsParams: IGetSecItemsContentParams = {
        dataIn: runwayDataObj.takeoff.lights,
        secTitle: SEC_TITLES.takeOff_Lights,
        margin: [0, 4],
        tableWidth: '35%',
    }
    const performanceItemsParams: IGetSecItemsContentParams = {
        dataIn: runwayDataObj.takeoff.performance,
        secTitle: SEC_TITLES.takeOff_Performance,
        margin: [0, 4],
        tableWidth: '65%',
    }

    return [
        {
            margin: [4, 0],
            border: [true, false, true, false],
            columns: [
                ..._getSecItemsContent({ ...lightsItemsParams }),
                ..._getSecItemsContent({ ...performanceItemsParams }),
            ],
            columnGap: 10
        },
    ] as TableCell;
}

const _getSecContentEngineFailureContent = (rawContent) => {
    console.log(rawContent);
    const htmlArr = htmlToPdfmake(rawContent, {
        tableAutoSize:true
    });
    for (const item of htmlArr){
        item.margin = [4, 0, 4, 4];
        item.style = 'secContentEngineFailure';
    }
    return [htmlArr];
}

const _getContainerContent = (runwayDataObj, contentSecsShowObj: IContentSecsShowObj) => {
    const notesTitleText = 'Notes (Applicable for all sections)';
    const lvoApproachItemsParams: IGetSecItemsContentParams = {
        dataIn: runwayDataObj.lvoApproach,
        secTitle: SEC_TITLES.lvoApproach,
        fixedCols: ['type', 'subType'],
    }
    const approachItemsParams: IGetSecItemsContentParams = {
        dataIn: runwayDataObj.approach,
        secTitle: SEC_TITLES.approach
    }

    let bodyContent: Array<TableCell> = [];
    if (contentSecsShowObj.notes){
        bodyContent.push(_getSecTitleContent(notesTitleText));
        bodyContent.push(_getSecTextContent(runwayDataObj.notes));
    }
    if(contentSecsShowObj.lvoApproach){
        bodyContent.push(_getSecTitleContent(SEC_TITLES.lvoApproach));
        bodyContent.push(_getSecItemsContent({ ...lvoApproachItemsParams }));
        bodyContent.push(_getSecContentRemarksContent(runwayDataObj?.lvoApproachRemark));
    }
    if(contentSecsShowObj.approach){
        bodyContent.push(_getSecTitleContent(SEC_TITLES.approach));
        bodyContent.push(_getSecItemsContent({ ...approachItemsParams }));
        bodyContent.push(_getSecContentRemarksContent(runwayDataObj?.approachRemark));
    }
    if(contentSecsShowObj.takeOff){
        bodyContent.push(_getSecTitleContent(SEC_TITLES.takeOff));
        bodyContent.push(_getSecContentTakeoffContent(runwayDataObj));
        bodyContent.push(_getSecContentRemarksContent(runwayDataObj?.takeoffRemark));
    }
    if(contentSecsShowObj.engineFailure){
        bodyContent.push(_getSecTitleContent(SEC_TITLES.engineFailure));
        bodyContent.push(_getSecContentEngineFailureContent(runwayDataObj.engineFailure));
    }

    return {
        margin: [0, 4],
        layout: containerLayout,
        style: 'container',
        table: {
            widths: ['100%'],
            body: bodyContent
        }
    } as Content;
}

const getPDFContentArray = (runwayDataObj, contentSecsShowObj: IContentSecsShowObj) => {
    return [
        _getHeaderContent(runwayDataObj) as Content,
        _getSubHeaderContent(runwayDataObj) as Content,
        _getContainerContent(runwayDataObj, contentSecsShowObj) as Content,
    ] as object[];
}

//#endregion

// check if all values in sec object are empty, data structure like [{info:[{value:''}]},{info:[{value:''}]}]
function areAllValuesInSecOjectEmpty(arr, emptyChart = '') {
    return arr.every(item => 
      item.info.every(infoItem => infoItem.value === emptyChart)
    );
}

const outputService = {
    getRunways,
    postRunwaySave,
    getCleanRunwayObj,
    getRunwayPDF,
    getFixedValueColumns,
    getConvertedSecData,
    convertItemToRawDataFormat,
    getPDFContentArray,
    getDefaultNewRunwayObject,
    areAllValuesInSecOjectEmpty
};

export default outputService;
export type { IContentSecsShowObj };
