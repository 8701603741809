import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useLocation } from "react-router-dom";

import GenericTable from '../../components/AdminDashboard/GenericTable';
import HeadTitle from '../../components/AdminDashboard/HeadTitle';
import { GENERIC_TABLE_TYPES } from '../../constants/genericTableConstant';
import { getUODefects } from '../../services/bekolDispatchAPI';
import { selectDispatcher, resetDefects, setDefects, resetCXDefects, setCXDefects } from '../../slice/dispatcherSlice';
import { FLIGHT_PLAN_CONFIG_PATHS, UO_PATHS } from "../../constants/path";
import { getCXDefects, postCXDefectRemarkSave, IDefectRemarkSavePayload } from '../../services/flightPlanConfigAPI';
import CXDefectTable from '../../components/AdminDashboard/CXDefectTable';
import { CRow, CCol} from '@coreui/react-pro';
import InputBoxGenerator from '../../components/CXForm/InputBoxGenerator';

import CXButton from "../../components/CXForm/CXButton";

/**
 * Application home page component
 * @returns {JSX}
 */

const SharedDefectsPage = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { defect: uoDefects, cxDefects } = useAppSelector(selectDispatcher);
  const [defectType, setDefectType] = useState<string>("P");
  const [combineSearchKey, setCombineSearchKey] = useState("");
  const [aircraft, setAircraft] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [deletedAt, setDeletedAt] = useState("");
  const [defectTableData, setDefectTableData] = useState<any>([]);
  const isCXDefects = pathname === FLIGHT_PLAN_CONFIG_PATHS.defects;
  const isUODefects = pathname === UO_PATHS.defect;

  const defectTypeOptions = [
    {
      text: "ALL",
      value: "ALL"
    },
    {
      text: "P",
      value: "P"
    }
  ]
  const statusOptions = [
    {
      text: "ALL",
      value: "ALL",
    },
    {
      text: "True",
      value: "true",
    },
    {
      text: "False",
      value: "false",
    },
  ];
  const cxDefectColName: Array<string> = [
    "Aircraft",
    "ADD#",
    "ADD Title",
    "ADD Detail",
    "MEL ID",
    "Expiry Date UTC",
    "Change Time UTC",
    "Deleted",
    "Remark"
  ];

  const uoDefectColName: Array<string> = [
    "Aircraft",
    "ADD#",
    "ADD Title",
    "ADD Detail",
    "MEL ID",
    "Expiry Date UTC",
    "Change Time UTC",
    "Deleted",
  ];

  interface IDefectsContainer {
    headTitle: string,
    tableType: string,
    tableColNames: Array<any>,
    tableData: Array<any>,
  }

  interface searchFieldType{
    key: string
    placeholder: string,
  }

  let defectsConObj: IDefectsContainer = {
    headTitle: '',
    tableType: '',
    tableColNames: [],
    tableData: []
  }

  const fillDefectData = (defectListResponse: any) => {
    if(!defectListResponse) return;
    let defectData = defectListResponse.defects;
    setDefectTableData(defectData)
  }


  const searchDefect = async() => {
    const res = await getCXDefects(aircraft,combineSearchKey,expiryDate,deletedAt);
    fillDefectData(res);
  }

  const onChangeField = (value: any, field: string) => {
    switch (field) {
      case "combineSearchKey":
        setCombineSearchKey(value);
        break;
      case "aircraft":
        setAircraft(value);
        break;
      case "expiryDate":
        setExpiryDate(value);
        break;
      case "deletedAt":
        setDeletedAt(value);
        break;
      default:
        break;
    }
  }

  const onAssignChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    let changedField = event.target.name,
        changedValue = event.target.value;
    onChangeField(changedValue, changedField);
  }

  const getDefect = async () => {
    let response;
    if (isUODefects) {
      response = await getUODefects(defectType);
    } else if (isCXDefects) {
      response = await getCXDefects();
    }

    if (!!response) {
      fillDefectData(response);
    }
  }

  const onChangeDefectType = (value: string) => {
    setDefectType(value);
    getDefect();
  }

  const cxDefectInputBox = [
    {
        gridSize: 1,
        inputType: "text",
        inputBoxProps:{
            field: "aircraft",
            placeholder: "Aircraft",
            value: aircraft,
            onChangeField: onAssignChangeValue
        }
    },
    {
        gridSize: 2,
        inputType: "text",
        inputBoxProps:{
            field: "combineSearchKey",
            placeholder: "ADD No. / ADD Title/ ADD Detail/ MEL ID",
            value: combineSearchKey,
            onChangeField: onAssignChangeValue
        }
    },
    {
        gridSize: 1,
        inputType: "date",
        key: "expiryDate",
        inputBoxProps:{
            field: "expiryDate",
            placeholder: "Expiry Date",
            value: expiryDate,
            onChangeField: onChangeField,
            hasDefault: false,
            zIndex: 1330

        }
    },
    {
        gridSize: 1,
        inputType: "dropdown",
        inputBoxProps:{
            field: "deletedAt",
            placeholder: "Status",
            onChangeField: onChangeField,
            value: deletedAt,
            options: statusOptions,
            zIndex: 1319
        }
    },
  ];

  const uoDefectInputBox = [  
    {
      gridSize: 1,
      inputType: "dropdown",
      inputBoxProps:{
          field: "defectType",
          placeholder: "Defect Type",
          onChange: onChangeDefectType,
          value: defectType,
          options: defectTypeOptions,
          zIndex: 1319
      }
    }
  ];

  const renderInputBox = isUODefects ? uoDefectInputBox : cxDefectInputBox;


  useEffect(() => {
    getDefect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <HeadTitle headTitle="Defects" />
      <div style={{ marginTop: "40px" }}>
        {<CRow>
            {renderInputBox.map((item, index) => (
              <InputBoxGenerator key={index} {...item}></InputBoxGenerator>
            ))}
            {isCXDefects && <CCol>
              <div className={"searchButton"} style={{ maxWidth: "172px" }}>
                <CXButton text={"Search"} onClick={() => {searchDefect()}}fontSize={"16px"}/>
              </div>
            </CCol>}
          </CRow>
        }
      </div>
      <div className={"section"}>
        {isUODefects &&
          <GenericTable
            type={GENERIC_TABLE_TYPES.defect}
            fieldNames={uoDefectColName}
            data={defectTableData || []}
          />}
        {isCXDefects &&
          <CXDefectTable
            type={GENERIC_TABLE_TYPES.defect}
            fieldNames={cxDefectColName}
            data={defectTableData || []}
            saveRemark={
              (payload: IDefectRemarkSavePayload) => {
                postCXDefectRemarkSave(payload).then((res) => {
                  if (!!res) {
                    const updatedCXDefects = defectTableData.map(defect =>
                      defect._id === res._id ?
                        { ...defect, remark: res.remark }
                        : defect
                    );
                    fillDefectData({ defects: updatedCXDefects });
                  }
                }).catch((err) => {
                  console.log(err);
                });
              }
            }
          />}
      </div>
    </>
  );
};

export default SharedDefectsPage;