import React, { useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { CRow, CCol, CAlert, CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody } from '@coreui/react-pro';
import { CXButton, CXTextArea } from '../../components/CXForm';
import { Form} from 'react-bootstrap';
import NoDataTableItem from '../../components/AdminDashboard/NoDataTableItem';
import CfCcsTableItem from '../../components/AdminDashboard/CfCcsTableItem';
import { getCfCcsData, updateCfCcsData } from '../../services/supportAPI';

/**
 * Application home page component
 * @returns {JSX}
 */

const DataCfCcsPage = () => {
  const [searchString, setSearchString] = useState<string>("");
  const [visible, setVisible] = useState(false);
  const [unsetRes, setUnsetRes] = useState<any>();
  const [cfccsIndexList, setCfccsIndexList] = useState<number[]>([]);
  const [cfCcsList, setCfCcsList] = useState<{
    _id: string,
    ufi: string,
    Autoland: string,
    Destination: string,
    FlightDate: string,
    LandingERN: string,
    Origin: string,
    SectorSeqNum: string,
    TakeoffERN: string,
    cfid: string,
    created: string,
    filename: string,
    sent: string,
    isChecked: boolean
  }[]>([]);

  const search = () => {
    setCfccsIndexList([]);
    getCfCcsData({search: searchString})
      .then((res) => {
        res.cfCcs.forEach(r => {
          r.isChecked = false;
        });
        setCfCcsList(res.cfCcs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const selectAll = () => {
    let checkDataList = [...cfCcsList];
    let checkIndexList = [...cfccsIndexList];
    for (let index = 0; index < cfCcsList.length; index++) {
      checkDataList[index].isChecked = true;
      if (checkIndexList.indexOf(index) < 0) {
        checkIndexList.push(index);
      }
    }
    setCfCcsList(checkDataList);
    setCfccsIndexList(checkIndexList);
  };


  const markNotSent = () => {
    let idArr: string[] = [];
    let ufiArr: string[] = [];
    if (cfccsIndexList.length < 1) {
      alert("Please Select Items");
    } else {
      for (let index = 0; index < cfccsIndexList.length; index++) {
        idArr.push(cfCcsList[cfccsIndexList[+index]]._id);
        ufiArr.push(cfCcsList[cfccsIndexList[+index]].ufi);
      }
      // Special meaning, using "-1" means that we will unset these field
      // example: "updateObj": {"sent":"-1","filename":"-1"}  means {$unset: {sent: 1, filename: 1}}
      updateCfCcsData({idArr: idArr, ufiArr: ufiArr, updateObj: {"sent":"-1","filename":"-1"}})
      .then((res) => {
        search();
        setUnsetRes(res.result);
        setVisible(true);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  const onClickCheckbox = (index: number) => {
    let checkList = [...cfccsIndexList];
    if(checkList.includes(index)){
      var valueIndex = checkList.indexOf(index);
      if(valueIndex !== -1){
        checkList.splice(valueIndex,1)
      }
    }else{
      checkList.push(index);
    }
    let checkDataList = [...cfCcsList];
    checkDataList[index].isChecked = !checkDataList[index].isChecked;
    setCfccsIndexList(checkList);
    setCfCcsList(checkDataList);
  }

  return (
    <>
      <div 
        className={"CathaySansBd"} 
        style={{ fontSize: "32px", lineHeight: "36px", marginBottom: "40px" }}
      >
        Data-cf_ccs
      </div>

      <div style={{ marginBottom: "40px" }}>
      <CRow>
        <CCol sm={4}>
          <CXTextArea minRows={2} maxRows={6} value={searchString} onChange={setSearchString}/>
        </CCol>
        <CCol sm={2} >
          <CXButton 
            text={"Search"} 
            onClick={() => { search() }} 
            fontSize={"16px"} width={"100%"} height = {"50px"} />
        </CCol>
        <CCol sm={2} >
          <CXButton 
            text={"Select All"} 
            onClick={() => { selectAll() }} 
            fontSize={"16px"} width={"60%"} height = {"50px"}  />
        </CCol>
        <CCol sm={2} ></CCol>
        <CCol sm={2} className={'mark-not-sent-button'}>
            <CXButton text={"Mark not sent"} 
              onClick={() => markNotSent()} fontSize={"16px"} width={"100%"} height = {"50px"}
            />
        </CCol>
      </CRow>
      </div>

      <div className="remark" style={{ marginBottom: "40px" }}>
        <Form.Label className="remark-title">Remarks</Form.Label>
        <div className="remark-value">
          <Form.Text className="remark-value-text">IMT Internal Use only.</Form.Text>
        </div>
        <div className="remark-value">
          <Form.Text className="remark-value-text">After mark unsent, please trigger /ccs API in eff-close repo</Form.Text>
        </div>
      </div>

      <CRow>
        <CCol className={'data-cfccs-list'}>
          <CTable borderless hover>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">
                  
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                  ufi
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                  Autoland
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                Destination
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                FlightDate
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                LandingERN
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                Origin
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                SectorSeqNum
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                TakeoffERN
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                cfid
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                created
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                filename
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                sent
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
          
            <CTableBody>
              {!!cfCcsList && cfCcsList.length !==0? 
                  cfCcsList.map((cfCcs, index) => 
                  <CfCcsTableItem key={`CfCcsTableItem_${index}`} index={index} cfCcs={cfCcs} onUpdateCfCcsIndex={onClickCheckbox}/>)
                  : 
                  (<NoDataTableItem colSpan={3} message={"There is no cf_ccs Data"} />) 
              }
            </CTableBody>
          </CTable>
        </CCol>
      </CRow>

      <CAlert color="primary" dismissible visible={visible} onClose={() => setVisible(false)}>
       {JSON.stringify({unsetRes})}
      </CAlert>
    </>
  );
};

export default DataCfCcsPage;
