import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

interface UserSlice {
    userId: string;
    access: object;
    allowedRoutes: Array<string>;
};

const initialState: UserSlice = {
    userId: "",
    access: {},
    allowedRoutes: [],
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, { payload }) => {
            // const { userId, access, allowedRoutes } = payload || {}; //--- comment for FCDOP3-3547 removing mongodb RBAC logic---//
            const { userId } = payload || {};
            state.userId = userId;
            //--- Start comment for FCDOP3-3547 removing mongodb RBAC logic---//
            // state.access = access;
            // state.allowedRoutes = allowedRoutes; 
            //--- End comment for FCDOP3-3547 removing mongodb RBAC logic---//
        },
        clearUser: (state) => {
            state.userId = "";
            //--- Start comment for FCDOP3-3547 removing mongodb RBAC logic---//
            // state.access = {};
            // state.allowedRoutes = [];
            //--- End comment for FCDOP3-3547 removing mongodb RBAC logic---//
        }
    }
});

const selectUser = (state: RootState) => state.user;
const { setUser, clearUser } = userSlice.actions;

export { userSlice, selectUser, setUser, clearUser };
export type { UserSlice };

export default userSlice.reducer;
