import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import GenericTable from '../../components/AdminDashboard/GenericTable';
import { GENERIC_TABLE_TYPES } from '../../constants/genericTableConstant';
import { getUOSnnRules } from '../../services/bekolDispatchAPI';
import { resetSnnRules, selectDispatcher, setSnnRules } from '../../slice/dispatcherSlice';

/**
 * Application home page component
 * @returns {JSX}
 */


const UOSnnRulesPage = () => {
  const dispatch = useAppDispatch();
  const { snnRule } = useAppSelector(selectDispatcher);
  const snnRuleColName: Array<string> = [
    "AL",
    "FLT NO.",
    "A/C Type",
    "TAIL",
    "ORIG",
    "DEST",
    "ALTN",
    "ERA",
    "Eff START UTC",
    "Eff END UTC",
    "TIME RANGE",
    "WAYPOINT",
    "Country Code",
    "Uploaded By",
    "SNN",
  ];

  useEffect(() => {
    dispatch(resetSnnRules());
    const getSnnRules = async () => {
      const response = await getUOSnnRules();

      if (!!response) {
        dispatch(setSnnRules(response));
      }
    }
    getSnnRules();
  }, [dispatch])

  return (
    <>
      <div className='p-0 m-0 d-flex align-items-center'>
        <div className={"pageHeading CathaySansBd"} style={{ fontSize: "32px", lineHeight: "36px" }}>SNN Rules</div>
      </div>
      <div className={"section"}>
        {/* <SnnRolesTable snnRules={snnRule || []} /> */}
        <GenericTable
          type={GENERIC_TABLE_TYPES.snnRule}
          fieldNames={snnRuleColName}
          data={snnRule || []}
        />
      </div>
    </>
  );
};

export default UOSnnRulesPage;
