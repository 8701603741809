import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getUOClosedFlightDetail } from '../../services/bekolDispatchAPI';
import { selectDispatcher, setCloseFlightDetail } from '../../slice/dispatcherSlice';
import CloseFlightDetailContent from '../../components/AdminDashboard/CloseFlightDetailContent';

/**
 * Application home page component
 * @returns {JSX}
 */

const UOCloseFlightDetailPage = () => {
  const params = useParams()
  const { ufi, closeFlightPackageIdentifier } = params;
  const dispatch = useAppDispatch();
  const { closeFlightDetail } = useAppSelector(selectDispatcher);

  useEffect(() => {
    getUOClosedFlightDetail(ufi).then(res => {
      dispatch(setCloseFlightDetail(res));
    }).catch(err => {
      console.log(err);
    });
  }, [ufi, dispatch]);

  return (
    <>
      <CloseFlightDetailContent user={"uo"} ufi={ufi} closeFlightPackageIdentifier={closeFlightPackageIdentifier} closeFlightDetail={closeFlightDetail}/>
    </>
  );
};

export default UOCloseFlightDetailPage;