import { CRow, CCol } from '@coreui/react-pro';
import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import _ from 'lodash';

import { CXButton } from '../CXForm';
import CXFPConfigTextInput from '../CXForm/CXFPConfigTextInput';
import TagsInput from '../TagInputs';
import MultiSelectDropdown from '../MultiSelectDropdown';
import { OPERATION_TYPE, TAB_OPTIONS_MUTIL_SEL_DD, TAG_FIELDS_KEY, TAG_MODAL_VALIDATION_MSG } from '../../constants/crewFeedbackTaggingPageConstant';

interface ICrewFeedbackTagModalProps {
  isAddNew,
  isShow,
  tagModal: any;
  setTagModal: any;
  isTagInputEntered: boolean;
  setIsTagInputEntered: Function;
  onHide: Function;
  onCancelClick: Function;
  onConfirmClick: Function;
}

const CrewFeedbackTagModal = (props: ICrewFeedbackTagModalProps) => {
  const { isAddNew, isShow, tagModal, setTagModal, isTagInputEntered, setIsTagInputEntered, onHide, onCancelClick, onConfirmClick } = props;

  const [hasValidationError, setHasValidationError] = useState<boolean>(false);

  const onChangeModal = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tempObj = {
      ...tagModal
    }
    tempObj[event.target.name] = event.target.value;
    setTagModal(tempObj);
  }

  const onChangeField = (value: any, field: string, state: any, setState: Function) => {
    let tempObj = {
      ...state
    }
    tempObj[field] = value;
    setState(tempObj);
  }

  const tagFormValidation = (value: string | string[]) => {
    if (hasValidationError) {
      if (typeof value === "string") {
        return value && value.length > 0;
      } else if (typeof value === "object" && _.isArray(value)) {
        return value && value.length > 0;
      }
    }

    return true;
  }

  const onClickConfirm = () => {
    // check if fulfill all fields
    let hasError = false;
    if (_.isEmpty(tagModal[TAG_FIELDS_KEY.TAG])
      || _.isEmpty(tagModal[TAG_FIELDS_KEY.PORT])
      || _.isEmpty(tagModal[TAG_FIELDS_KEY.TAB])
    ) {
      hasError = true;
      window.alert(TAG_MODAL_VALIDATION_MSG);
    }
    if (!hasError)
      onConfirmClick(isAddNew ? OPERATION_TYPE.MODAL_ADD_NEW : OPERATION_TYPE.MODAL_EDIT_CONFIRM, tagModal);
  }

  const renderModal = () => {
    return (
      <Modal className='tagModal' size='lg' show={isShow} backdrop="static" onHide={() => { onHide() }}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title-tag">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRow className='mt-2'>
            <CCol xs={5}>
              <span className='modal-section-title'> Tag Name</span>
            </CCol>
            <CCol xs={7}>
              <CXFPConfigTextInput
                placeholder={"Tag Name"}
                value={tagModal.tag}
                onChangeField={onChangeModal}
                field="tag"
                additionalValidation={tagFormValidation}
                errMsg={"Required"}
                zIndex={1029} />
            </CCol>
          </CRow>
          <CRow className='mt-2'>
            <CCol xs={5}>
              <span className='modal-section-title'> Port</span>
            </CCol>
            <CCol xs={7}>
              <TagsInput placeholder={"Port (Use “enter” key for multiple input)"} tagList={tagModal.port}
                onChangeField={(value, field, state, setState) => {
                  setIsTagInputEntered(true);
                  onChangeField(value, field, state, setState);
                }}
                field="port" state={tagModal} setState={setTagModal} additionalValidation={v => !isTagInputEntered || v.length > 0} errMsg="Required" upperCase={true}></TagsInput>
            </CCol>
          </CRow>
          <CRow className='mt-2 pt-4'>
            <CCol xs={5}>
              <span className='modal-section-title'> Tab</span>
            </CCol>
            <CCol xs={7}>
              <MultiSelectDropdown
                defaultOptions={TAB_OPTIONS_MUTIL_SEL_DD}
                values={tagModal.tab}
                zIndex={1319}
                onChangeField={onChangeField}
                field="tab"
                state={tagModal}
                onChange={setTagModal} />
            </CCol>
          </CRow>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <div className='cancel-button'><CXButton outline={true} text={"Cancel"} onClick={() => onCancelClick()} fontSize={"16px"} width={"100%"} /></div>
          <div className='save-button'><CXButton text={btnLabel} onClick={() => onClickConfirm()} fontSize={"16px"} width={"100%"} /></div>
        </Modal.Footer>
      </Modal>
    )
  }

  const title = isAddNew ? "Add new tag" : "Edit tag";
  const btnLabel = isAddNew ? "Add new" : "Confirm";

  return tagModal && renderModal();

};

export default CrewFeedbackTagModal;
