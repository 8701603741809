import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { CXButton, CXDatePicker, CXDropDown, CXTextInput } from '../../components/CXForm';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { initialNewSNNRule, initialNewHKESNNRule, initialSNNSearch, ISNNRule, ISNNSearch, selectSim, setSNNRules, ISNNRuleRequest, initialSNNReducer } from '../../slice/simSlice';
import { Form, Modal,  } from 'react-bootstrap';
import SNNRulesTable from '../../components/Sim/HKESNNRulesTable';
import { CRow, CCol, CTable, CTableHeaderCell, CTableRow, CTableHead, CTableBody, CTableDataCell } from '@coreui/react-pro';
import CXTimePicker from '../../components/CXForm/CXTimePicker';
import TagsInput from '../../components/TagInputs';
import ViewLabel from '../../components/ViewLabel';
import { SNN_REQUIRED_FIELDS, SNN_SEARCH_FIELDS, WEEKDAYS_MULTISELECT, WEEKDAYS_MULTISELECT_VALUE } from '../../constants/simConstant';
import { getHKESnnRules, postHKECreateSnnRule, postHKEUpdateSnnRule, deleteHKESnnRule } from '../../services/snnAPI';
import ToggleSwitch from '../../components/ToggleSwitch';
import { getAircraftTypeList } from '../../services/commonAPI';
import CXFPConfigTextInput from '../../components/CXForm/CXFPConfigTextInput';
import CXFPConfigTextArea from '../../components/CXForm/CXFPConfigTextArea';
import MultiSelectDropdown from '../../components/MultiSelectDropdown';
import { selectUser } from '../../slice/userSlice';
import Access from '../../components/Access';
import { AccessType } from '../../constants/roleAccessConstant';
import { useLocation } from "react-router-dom";
import SNNSearchBar from '../../components/Sim/HKESNNSearchBar';

const HKESNNRulePage = () => {
  const { pathname: routePath } = useLocation();
  const dispatch = useAppDispatch();
  const { snnRules } = useAppSelector(selectSim);
  const [aircraftTypeList, setAirCraftTypeList] = useState<[{
    text: string,
    value: string
  }]>([{
    text: "",
    value: ""
  }]);
  const [hasValidationError, setHasValidationError] = useState<boolean>(false);
  const [hasChangeField, setHasChangeField] = useState<boolean>(false);
  const [activeStatus, setActiveStatus] = useState<string>("");
  const [interval, setInterval] = useState<string>("");
  const [activeCategory, setActiveCategory] = useState<string>("");

  const [snnForm, setSNNForm] = useState<ISNNRule>(initialNewHKESNNRule);
  const [searchCriteria, setSearchCriteria] = useState<ISNNSearch>(initialSNNSearch);

  const [hasStartValue, setHasStartValue] = useState<boolean>(false);
  const [hasEndValue, setHasEndValue] = useState<boolean>(false);
  const [hasRangeValue, setHasRangeValue] = useState<boolean>(false);
  const [hasDaysValue, setHasDaysValue] = useState<boolean>(false);

  const { userId } = useAppSelector(selectUser);

  const timeRangeDropDownOptions =  [
    {
      text: "BOTH",
      value: "BOTH"
    },
    {
      text: "DEP",
      value: "DEP"
    },
    {
      text: "ARR",
      value: "ARR"
    }
  ];

  const airlineOptions = [
    {
      text: "HKE",
      value: "HKE"
    }
  ]

  const defaultAircraftTypeOptions = [
    {
      text: "ALL",
      value: "ALL"
    },
    {
      text: "A*",
      value: "A*"
    },
    {
      text: "B*",
      value: "B*"
    }
  ]

  const intervalEndDays = [
    {
      text: "+0 Days",
      value: "0"
    },
    {
      text: "+1 Days",
      value: "1"
    }
  ]

  const intervalDays = WEEKDAYS_MULTISELECT_VALUE;

  const reducer = (state, action) => {
    switch (action.type) {
      case "NEW":
        return {
          ...state,
          body: initialNewHKESNNRule,
          isAddNewMode: true,
          isAddNewSNNRulesLightBoxVisible: true
        };
      case "EDIT":
        setSNNForm(action.snnRule);
        return {
          ...state,
          isAddNewMode: false,
          isAddNewSNNRulesLightBoxVisible: true
        };
      case "VIEW":
        return {
          ...state,
          body: action.snnRule,
          isViewSNNRulesLightBoxVisible: true
        };
      case "DELETE":
        return {
          ...state,
          body: action.snnRule,
          isDeleteSNNRulesLightBoxVisible: true
        };
      case "DUPLICATED":
        setSNNForm(action.snnRule);
        return {
          ...state,
          isAddNewMode: true,
          isDuplicated: true,
          isAddNewSNNRulesLightBoxVisible: true
        }
      case "CLOSE":
        setHasValidationError(false);
        return state.isViewSNNRulesLightBoxVisible === true ?  {
          ...state,
          isAddNewMode: false,
          isDuplicated: false,
          isAddNewSNNRulesLightBoxVisible: false,
          isDeleteSNNRulesLightBoxVisible: false
        } : {
          ...state,
          body: initialNewHKESNNRule,
          isAddNewMode: false,
          isDuplicated: false,
        }
      case "CLOSEALL":
        setHasValidationError(false);
        return {
          ...state,
          body: initialNewHKESNNRule,
          isAddNewMode: false,
          isDuplicated: false,
          isAddNewSNNRulesLightBoxVisible: false,
          isViewSNNRulesLightBoxVisible: false,
          isDeleteSNNRulesLightBoxVisible: false
        }
      default:
        return state;
    }
  };

  const [snnModel, dispatchReducer] = useReducer(reducer, initialSNNReducer)

  const snnFormValidation = (value: string | string[]) => {
    if(hasValidationError) {
      if(typeof value == "string"){
        return value && value.length > 0;
      } else if (typeof value == "object"){
        return value && value.length > 0;
      }
    }

    return true;
  }

  //Modal
  const handleClose = (modalFlag: string) => {
    setHasChangeField(false);
    setHasStartValue(false);
    setHasEndValue(false);
    setHasRangeValue(false);
    setHasDaysValue(false);
    if(snnModel.isViewSNNRulesLightBoxVisible === true){
      let tempState = snnModel;
      tempState[modalFlag] = false;
      dispatchReducer({type:"CLOSE", snnRule: tempState});
    } else {
      dispatchReducer({type:"CLOSEALL", snnRule: snnModel.body});
      snnModel[modalFlag] = false;
      setSNNForm(initialNewHKESNNRule);
    }
  };

  const closeModal = () => {
    setHasChangeField(false);
    setHasStartValue(false);
    setHasEndValue(false);
    setHasRangeValue(false);
    setHasDaysValue(false);
    dispatchReducer({type:"CLOSEALL", snnRule: snnModel.body});
    setSNNForm(initialNewHKESNNRule);
  }

  const getAircraftDropdown = useCallback(() => {
    getAircraftTypeList().then((res) => {
      let dropdownOptionList = res.map(type => {
        return {
          text:type._id,
          value: type._id
        }
      })
      dropdownOptionList = defaultAircraftTypeOptions.concat(dropdownOptionList);
    setAirCraftTypeList(dropdownOptionList || []);
    }).catch((err) => {
      console.log(err);
    });

  },[]);

  useEffect(() => {
    onClickSearch();
    getAircraftDropdown();
    setHasStartValue(false);
    setHasEndValue(false);
    setHasRangeValue(false);
    setHasDaysValue(false);
    setHasValidationError(false);
  }, []);

  const onClickSearch = () => {
    let tempObj = {};
    for (let key in searchCriteria) {
      if(searchCriteria[key] != ""){
        let searchContent = searchCriteria[key];
        if(key !== 'snn'){
          searchContent = searchContent.toUpperCase();
        }
        tempObj[key] = searchContent;
      }
    }

    getHKESnnRules(tempObj).then((res) => {
      const filterList = res.snnRules.filter(snn => typeof snn.endAt != "string");
      const HKEList = res.snnRules.filter(snn => snn.airline === 'HKE');
      dispatch(setSNNRules(Object.values(HKEList || {})));
    }).catch((err) => {
      console.log(err);
    });
    setSNNForm(initialNewHKESNNRule);
  }

  const onChangeSearchForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tempObj = {
      ...searchCriteria
    }
    tempObj[event.target.name] = event.target.value;
    setSearchCriteria(tempObj);
  }

  const onChangeModal = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tempObj = {
      ...snnForm
    }
    tempObj[event.target.name] = event.target.value;
    setHasChangeField(true);
    setSNNForm(tempObj);
  }

  const onChangeEditField = (value: any, field: string, state: any, setState: Function) => {
    let tempObj = {
      ...state
    }
    if(field == "intervalStartTime" || field =="intervalEndTime"){
      value = moment.utc(value);
      tempObj[field] = value.toISOString();
    }else{
      tempObj[field] = value;
    }
    setHasChangeField(true);
    setHasStartValue(false);
    setHasEndValue(false);
    setHasRangeValue(false);
    setHasDaysValue(false);
    setHasValidationError(false);
    setState(tempObj);
  }

  const onChangeField = (value: any, field: string, state: any, setState: Function) => {
    let tempObj = {
      ...state
    }
    if(field == "intervalStartTime" || field =="intervalEndTime"){
      value = moment.utc(value);
      tempObj[field] = value.toISOString();
    }else{
      tempObj[field] = value;
    }
    setState(tempObj);
  }

  const onClickCreate = () => {

    if (snnForm.isInterval) {
      if (!snnForm.intervalStartTime) {
        setHasStartValue(true);
      }
      if (!snnForm.intervalEndTime) {
        setHasEndValue(true);
      }
      if (snnForm.intervalEndDay === '0') {
        setHasRangeValue(true);
      }
      if (snnForm.intervalDays.length === 0) {
        setHasDaysValue(true);
      }
    }

    for (const field in snnForm) {
      if(SNN_REQUIRED_FIELDS.includes(field) && !snnForm[field] && (snnForm[field] == "" || snnForm[field].length == 0)) {
        setHasValidationError(true);
        return;
      }
    }

    if(snnModel.isAddNewMode){
      // create new snn
      const snnRequest: ISNNRuleRequest = _convertToRequest(snnForm);
      snnRequest.createBy = userId;
      snnRequest.version = 1;
      if(snnRequest['_id'] != undefined) {
        delete snnRequest['_id'];
      }
      setHasChangeField(false);
      postHKECreateSnnRule(snnRequest).then((res) => {
        dispatchReducer({type:"CLOSEALL", snnRule: snnModel.body});
        setHasValidationError(false);
        onClickSearch();
      }).catch((err) => {
        console.log(err);
      });
    } else{
      // update snn
      const snnRequest: ISNNRuleRequest = _convertToRequest(snnForm);
      snnRequest.createBy = userId;
      snnRequest.version = snnRequest.version + 1;
      if (!hasChangeField) {
        console.log('data not change');
      } else {
        setHasChangeField(false);
        postHKEUpdateSnnRule(snnForm._id, snnRequest).then((res) => {
          dispatchReducer({type:"CLOSEALL", snnRule: snnModel.body});
          setHasValidationError(false);
          onClickSearch();
        }).catch((err) => {
          console.log(err);
        });
      }
    }

    handleClose("isAddNewSNNRulesLightBoxVisible")
  }

  const _convertToRequest = (snnForm: ISNNRule): ISNNRuleRequest=> {
    return {
      ...snnForm,
      dep: snnForm.dep.join(","),
      arr: snnForm.arr.join(","),
      altn: snnForm.altn && snnForm.altn.trim().toUpperCase(),
      era: snnForm.era && snnForm.era.trim().toUpperCase(),
      intervalDays: snnForm.intervalDays.map(day => day.value),
      waypoint: snnForm.waypoint && snnForm.waypoint.trim().toUpperCase(),
      countryICAOCode: snnForm.countryICAOCode && snnForm.countryICAOCode.trim().toUpperCase(),
    }
  }

  const formatIntervalDaysDisplay = (intervalDays: any[]) => {
    const displayWeekDays: any[] = [];
    intervalDays.forEach((day: any) => {
      if(day.selected){
        displayWeekDays.push(day.text);
      }
    });
    const result = displayWeekDays.join(', ').toString();
    return !_.isEmpty(result) ? result : '-- ';
  }

  const confirmDelete = () => {
    //delete here
    deleteHKESnnRule(snnModel.body._id).then((res)=>{
      dispatchReducer({type:"CLOSEALL", snnRule: snnModel.body});
      onClickSearch();
    })
  }

  const RobotoMonoRgDataCell = ({ children }) => {
    return <CTableDataCell className={"RobotoMonoRg"} style={{ position: "relative" }} align='middle'>{ children }</CTableDataCell>
  };


  return (    
    <>
      <div className={"section d-flex justify-content-between"} >
        <div className={"CathaySansBd"} style={{ fontSize: "32px", lineHeight: "36px" }}>HKE SNN</div>
          <CRow className={"p-2 ms-auto"}>
            <CCol sm>
            <Access route={routePath} access={[AccessType.CREATE]} >
                <CXButton
                  onClick={() => {
                    dispatchReducer({type: "NEW", snnRule: initialNewHKESNNRule})
                  }}
                  text={"Add New SNN"}
                  outline={true}
                  fontSize={"14px"} />
            </Access>
            </CCol>
          </CRow>
      </div>

      <SNNSearchBar
        searchCriteria={searchCriteria}
        setSearchCriteria={setSearchCriteria}
        onChangeSearchForm={onChangeSearchForm}
        onChangeField={onChangeField}
        onClickSearch={onClickSearch}
        interval={interval}
        setInterval={setInterval}
        airlineOptions={airlineOptions}
        activeStatus={activeStatus}
        setActiveStatus={setActiveStatus}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        isCXSNN={false}
      />

      <div className={"section snnTable"}>
        <SNNRulesTable snnRules={snnRules || []} activeStatus={activeStatus} interval={interval} activeCategory={activeCategory} dispatchReducer={dispatchReducer} routePath={routePath}/>
      </div>

      {/* --------- Add SNN Modal ---------*/}
      <Modal className="snnModal" show={snnModel.isAddNewSNNRulesLightBoxVisible} onHide={closeModal} size="xl">
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title>
            {snnModel.isAddNewMode ? snnModel.isDuplicated ? "Duplicated SNN" : "Add New SNN" : "Edit SNN"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'> Aircraft type and flight details</span>
            </CCol>
            <CCol xs={6}>
              <CRow>
                <CCol xs={6}>
                  <CXDropDown
                    value={snnForm.airline}
                    placeholder={"Airline"}
                    onChange={setSNNForm}
                    options={airlineOptions}
                    onChangeField={onChangeEditField}
                    field="airline"
                    state={snnForm}
                    zIndex={1430}/>
                </CCol>
                <CCol xs={6}>
                  <CXFPConfigTextInput
                    placeholder={"Flight Number"}
                    value={snnForm.flightNo}
                    onChangeField={onChangeModal}
                    field="flightNo"
                    additionalValidation={snnFormValidation}
                    errMsg={"Required"}
                    zIndex={1031}/>
                </CCol>
              </CRow>
              <CRow>
                <CCol xs={6}>
                  <CXDropDown
                    value={snnForm.aircraftType}
                    placeholder={"Aircraft type"}
                    onChange={setSNNForm}
                    options={aircraftTypeList}
                    onChangeField={onChangeEditField}
                    field="aircraftType"
                    state={snnForm}
                    zIndex={1330}/>
                </CCol>
                <CCol xs={6}>
                  <CXFPConfigTextInput
                    placeholder={"Registration (Example:ABC)"}
                    value={snnForm.registration}
                    onChangeField={onChangeModal}
                    field="registration"
                    additionalValidation={snnFormValidation}
                    errMsg={"Required"}
                    zIndex={1029}/>
                </CCol>
              </CRow>
              <TagsInput placeholder={"Origin (Use “enter” key for multiple input)"} tagList={snnForm.dep} onChangeField={onChangeEditField} field="dep" state={snnForm} setState={setSNNForm} additionalValidation={v => v.length > 0} errMsg="Required"></TagsInput>
              <TagsInput placeholder={"Dest (Use “enter” key for multiple input)"} tagList={snnForm.arr} onChangeField={onChangeEditField} field="arr" state={snnForm} setState={setSNNForm} additionalValidation={v => v.length > 0} errMsg="Required"></TagsInput>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>Effective start and end dates (UTC) </span>
            </CCol>
            <CCol xs={6}>
              <CRow>
                <CCol xs={6}>
                  <CXDatePicker
                    placeholder={"Effective start date"}
                    value={snnForm.startAt}
                    onChange={setSNNForm}
                    zIndex={1320}
                    onChangeField={onChangeEditField}
                    field="startAt"
                    timepicker
                    state={snnForm}
                    hasDefault={false}
                    additionalValidation={snnFormValidation}
                    errMsg={"Required"}/>
                </CCol>
                <CCol xs={6}>
                  <CXDatePicker
                    placeholder={"Effective end date"}
                    value={snnForm.endAt}
                    onChange={setSNNForm}
                    zIndex={1320}
                    onChangeField={onChangeEditField}
                    field="endAt"
                    timepicker
                    state={snnForm}
                    hasDefault={false}
                    additionalValidation={snnFormValidation}
                    errMsg={"Required"}/>
                </CCol>
              </CRow>
              <CRow>
                <CCol xs={4}>
                  <CXDropDown
                    value={snnForm.timeRange}
                    placeholder={"Time range"}
                    onChange={setSNNForm}
                    options={timeRangeDropDownOptions}
                    onChangeField={onChangeEditField}
                    field="timeRange"
                    state={snnForm}
                    zIndex={1319}/>
                </CCol>
              </CRow>
              <CRow>
                <div>
                  <ToggleSwitch name="flexCheckDefault" checked={snnForm.isInterval} onChangeField={onChangeEditField} field="isInterval" state={snnForm} setState={setSNNForm}></ToggleSwitch>
                  Enabled Interval
                </div>
              </CRow>
              <CRow className='modalRow'>
                <CCol xs={4}>
                  <CXTimePicker placeholder={"Start interval"} value={snnForm.intervalStartTime} onChange={setSNNForm} onChangeField={onChangeEditField} field="intervalStartTime" state={snnForm} zIndex={1229} disabled={!snnForm.isInterval}/>
                  { hasStartValue && <span style={ { fontSize: "14px", margin: "0 0 0 0", color: "red" } }>All fields have to be filled </span> }
                </CCol>
                <CCol xs={4}>
                  <CXTimePicker placeholder={"End interval"} value={snnForm.intervalEndTime} onChange={setSNNForm} onChangeField={onChangeEditField} field="intervalEndTime" state={snnForm} zIndex={1229} disabled={!snnForm.isInterval}/>
                  { hasEndValue && <span style={ { fontSize: "14px", margin: "0 0 0 0", color: "red" } }>All fields have to be filled </span> }
                </CCol>
                <CCol xs={4}>
                  <CXDropDown
                      value={snnForm.intervalEndDay}
                      placeholder={"Time range"}
                      onChange={setSNNForm}
                      options={intervalEndDays}
                      onChangeField={onChangeEditField}
                      field="intervalEndDay"
                      state={snnForm}
                      zIndex={1319}
                      disabled={!snnForm.isInterval}/>
                  { hasRangeValue && <span style={ { fontSize: "14px", margin: "0 0 0 0", color: "red" } }>All fields have to be filled </span> }
                </CCol>
              </CRow>
              <CRow className='modalRow'>
                <MultiSelectDropdown
                  defaultOptions={intervalDays}
                  values={snnForm.intervalDays}
                  zIndex={1319}
                  isDisabled={!snnForm.isInterval}
                  onChangeField={onChangeEditField}
                  field="intervalDays"
                  state={snnForm}
                  onChange={setSNNForm}/>
                  { hasDaysValue && <span style={ { fontSize: "14px", margin: "0 0 0 0", color: "red" } }>All fields have to be filled </span> }
              </CRow>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>Location information</span>
            </CCol>
            <CCol xs={6}>
              <CRow>
                <CCol xs={6}>
                  <CXFPConfigTextInput
                    maxLength={4}
                    placeholder={"ALTN"}
                    value={snnForm.altn}
                    onChangeField={onChangeModal}
                    field="altn"
                    zIndex={1029}/>
                </CCol>
                <CCol xs={6}>
                  <CXFPConfigTextInput
                    maxLength={4}
                    placeholder={"ERA"}
                    value={snnForm.era}
                    onChangeField={onChangeModal}
                    field="era"
                    zIndex={1029}/>
                </CCol>
              </CRow>
              <CRow>
                <CCol xs={6}>
                  <CXFPConfigTextInput
                    maxLength={5}
                    placeholder={"Waypoint"}
                    value={snnForm.waypoint}
                    onChangeField={onChangeModal}
                    field="waypoint"
                    additionalValidation={snnFormValidation}
                    errMsg={"Required"}
                    zIndex={1029}/>
                </CCol>
                <CCol xs={6}>
                  <CXFPConfigTextInput
                    maxLength={4}
                    placeholder={"Country code"}
                    value={snnForm.countryICAOCode}
                    onChangeField={onChangeModal}
                    field="countryICAOCode"
                    additionalValidation={snnFormValidation}
                    errMsg={"Required"}
                    zIndex={1029}/>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>SNN</span>
            </CCol>
            <CCol className="snnDes" xs={6}>
              <CXFPConfigTextArea
                maxRows={21}
                value={snnForm.snn}
                placeholder={"SNN Description"}
                onChangeField={onChangeModal}
                field="snn"
                additionalValidation={snnFormValidation}
                errMsg={"Required"}/>
            </CCol>
          </CRow>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <div className='cancel-button'><CXButton outline={true} text={"Cancel"} onClick={()=> handleClose("isAddNewSNNRulesLightBoxVisible")} fontSize={"16px"} width={"100%"} /></div>
          <div className='save-button'><CXButton text={snnModel.isAddNewMode ? "Add" : "Save"} onClick={()=> onClickCreate()} fontSize={"16px"} width={"100%"} /></div>
        </Modal.Footer>
      </Modal>

      {/* <NewSNNModal snnModel={snnModel} dispatchReducer={dispatchReducer} refreshSNNs={onClickSearch}/> */}
      {/* --------- View SNN Modal ---------*/}
      <Modal className="snnModal" show={snnModel.isViewSNNRulesLightBoxVisible} dialogClassName="modal-90w" onHide={closeModal} size="xl">
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title>View SNN</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>Aircraft type and flight details</span>
            </CCol>
            <CCol xs={6}>
              <CRow className='modalRow'>
                <CCol xs={6}>
                  <ViewLabel label="Airline" value={snnModel.body.airline}></ViewLabel>
                </CCol>
                <CCol xs={6}>
                  <ViewLabel label="Flight Number" value={snnModel.body.flightNo}></ViewLabel>
                </CCol>
              </CRow>
              <CRow className='modalRow'>
                <CCol xs={6}>
                  <ViewLabel label="Airline" value={snnModel.body.aircraftType}></ViewLabel>
                </CCol>
                <CCol xs={6}>
                  <ViewLabel label="Registeration" value={snnModel.body.registration}></ViewLabel>
                </CCol>
              </CRow>
              <CRow className='modalRow'>
                <>
                  <div className="CathaySansLt" style={{color:"#808285"}}>
                    {"Origin"}
                  </div>
                  <div className='tags-view-container'>
                    {snnModel.body.dep && snnModel.body.dep.length > 0 && snnModel.body.dep.map((dep, index) =>
                      <div className="tag-item-view" key={`Origin Tag${index}`}>
                          <span className="text">{dep}</span>
                      </div>)}
                  </div>
                </>
              </CRow>
              <CRow className='modalRow'>
                <>
                  <div className="CathaySansLt" style={{color:"#808285"}}>
                    {"Destination"}
                  </div>
                  <div className='tags-view-container'>
                    {snnModel.body.arr && snnModel.body.arr.length > 0? snnModel.body.arr.map((arr, index) =>
                      <div className="tag-item-view" key={`Dest Tag${index}`}>
                          <span className="text">{arr}</span>
                      </div>)
                      :
                      <></>}
                  </div>
                </>
              </CRow>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>Effective start and end dates</span>
            </CCol>
            <CCol xs={6}>
              <CRow className='modalRow'>
                <CCol xs={6}>
                  <ViewLabel label="Effective start date" value={snnModel.body.startAt ? moment.utc(snnModel.body.startAt)?.format("DDMMMYYYY HH:mm") : "--"}></ViewLabel>
                </CCol>
                <CCol xs={6}>
                  <ViewLabel label="Effective end date" value={snnModel.body.endAt ? moment.utc(snnModel.body.endAt)?.format("DDMMMYYYY HH:mm") : "--"}></ViewLabel>
                </CCol>
              </CRow>
              <CRow className='modalRow'>
                <ViewLabel label="Time range" value={snnModel.body.timeRange}></ViewLabel>
              </CRow>
              <CRow className='modalRow'>
                <div>
                  <ToggleSwitch name="flexCheckDefault" checked={snnModel.body.isInterval} onChangeField={onChangeField} field="isInterval" state={snnForm} setState={setSNNForm} isDisabled={true}></ToggleSwitch>
                  Enabled Interval
                </div>
              </CRow>
              <CRow className='modalRow'>
                <CCol xs={4}>
                  <ViewLabel label="Start interval" value={snnModel.body.intervalStartTime ? moment.utc(snnModel.body.intervalStartTime)?.format("HH:mm") :"-- "}></ViewLabel>
                </CCol>
                <CCol xs={4}>
                  <ViewLabel label="End interval" value={snnModel.body.intervalStartTime ? moment.utc(snnModel.body.intervalEndTime)?.format("HH:mm") :"-- "}></ViewLabel>
                </CCol>
              </CRow>
              <CRow className='modalRow'>
                <CCol xs={10}>
                  <ViewLabel label="Interval Days" value={formatIntervalDaysDisplay(snnModel.body.intervalDays)}></ViewLabel>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>Location information</span>
            </CCol>
            <CCol xs={6}>
              <CRow className='modalRow'>
                <CCol xs={6}>
                  <ViewLabel label="ALTN" value={snnModel.body.altn}></ViewLabel>
                </CCol>
                <CCol xs={6}>
                  <ViewLabel label="ERA" value={snnModel.body.era}></ViewLabel>
                </CCol>
              </CRow>
              <CRow className='modalRow'>
                <CCol xs={6}>
                  <ViewLabel label="Waypoint" value={snnModel.body.waypoint}></ViewLabel>
                </CCol>
                <CCol xs={6}>
                  <ViewLabel label="Country code" value={snnModel.body.countryICAOCode}></ViewLabel>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>SNN</span>
            </CCol>
            <CCol className="snnDes" xs={6}>
              <ViewLabel label="SNN Description" value={snnModel.body.snn}></ViewLabel>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>Uploaded By</span>
            </CCol>
            <CCol className="snnDes" xs={6}>
              {snnModel.body.createBy || '--'}
            </CCol>
          </CRow>
        </Modal.Body>
        <Access route={routePath} access={[AccessType.CREATE, AccessType.DELETE, AccessType.UPDATE]} >
          <CRow>
            <CCol>
              <Modal.Footer className="modal-footer-start">
              <Access route={routePath} access={[AccessType.UPDATE]} >
                <div className='save-button'><CXButton outline={true} text={"Duplicate"} onClick={()=> dispatchReducer({type:"DUPLICATED", snnRule: snnModel.body})} fontSize={"16px"} width={"100%"} /></div>
              </Access>
              </Modal.Footer>
            </CCol>
            <CCol>
            <Modal.Footer className="modal-footer">
            <Access route={routePath} access={[AccessType.DELETE]} >
              <div className='cancel-button'><CXButton outline={true} text={"Delete"} onClick={()=> dispatchReducer({type:"DELETE", snnRule: snnModel.body})} fontSize={"16px"} width={"100%"} /></div>
              </Access>
              <Access route={routePath} access={[AccessType.UPDATE]} >
              <div className='save-button'><CXButton text={"Edit"} onClick={()=> dispatchReducer({type:"EDIT", snnRule: snnModel.body})} fontSize={"16px"} width={"100%"} /></div>
              </Access>
            </Modal.Footer>
            </CCol>
          </CRow>
        </Access>
      </Modal>

      {/* --------- Delete SNN Modal ---------*/}
      <Modal className="snnModal" show={snnModel.isDeleteSNNRulesLightBoxVisible} dialogClassName="modal-90w" onHide={closeModal} size="xl">
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title>Delete SNN</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRow>
            Are you sure to delete this SNN?
          </CRow>
          <CTable className="snnTable" borderless hover>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"}>
                  Airline
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"}>
                  Flt no
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"}>
                  A/C type
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"}>
                  Reg
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"}>
                  <div>Orig</div>
                  <div>Dest</div>
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"}>
                  <div>ALTN</div>
                  <div>ERA</div>
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"}>
                  <div>Eff Start Date (UTC)</div>
                  <div>Eff End Date (UTC)</div>
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"}>
                  SNN
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {(!!snnModel.body && <CTableRow style={{ borderBottom: "1px solid #D8D8D8" }}>
                <RobotoMonoRgDataCell>{snnModel.body.airline || "--"} {snnModel.body.flightNo || "--"}</RobotoMonoRgDataCell>
                <RobotoMonoRgDataCell>{snnModel.body.aircraftType || "--"}</RobotoMonoRgDataCell>
                <RobotoMonoRgDataCell>{snnModel.body.registration || "--"}</RobotoMonoRgDataCell>
                <RobotoMonoRgDataCell>{Array.isArray(snnModel.body.dep) && snnModel.body.dep.map((ori) => ori)}</RobotoMonoRgDataCell>
                <RobotoMonoRgDataCell>{Array.isArray(snnModel.body.arr) && snnModel.body.arr.map((arr) => arr)}</RobotoMonoRgDataCell>
                <RobotoMonoRgDataCell>
                  <div>
                    {snnModel.body.altn || "--"}
                  </div>
                  <div>
                    {snnModel.body.era || "--"}
                  </div>
                </RobotoMonoRgDataCell>
                <RobotoMonoRgDataCell>
                <div>
                  {moment.utc(snnModel.body.startAt)?.format("DDMMMYYYY HH:mm") || "--"}
                </div>
                <div>
                  {moment.utc(snnModel.body.endAt)?.format("DDMMMYYYY HH:mm") || "--"}
                </div>
              </RobotoMonoRgDataCell>
              <RobotoMonoRgDataCell>{snnModel.body.snn || "--"}</RobotoMonoRgDataCell>
              </CTableRow>)}
            </CTableBody>
          </CTable>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <div className='cancel-button'><CXButton outline={true} text={"Cancel"} onClick={()=> handleClose("isDeleteSNNRulesLightBoxVisible")} fontSize={"16px"} width={"100%"} /></div>
          <div className='save-button'><CXButton text={"Delete"} onClick={()=> confirmDelete()} fontSize={"16px"} width={"100%"} /></div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HKESNNRulePage;
