import React, { useEffect, useRef, useState } from 'react';
import { CCol, CRow } from '@coreui/react';
import { selectSim } from '../../slice/simSlice';
import { selectAuth } from '../../slice/authSlice';
import { useAppSelector } from '../../app/hooks';
import { CXTextInput, CXButton, CXDatePicker } from '../CXForm';
import AddOrEditOFPLightBox from './AddOrEditOFPLightBox';
import AppendixFilesTable from './AppendixFilesTable';
import moment from 'moment';
import { uploadAppendixFile} from '../../constants/simConstant';
import { getAppendixFiles, getHKEAppendixFiles } from '../../services/appendixAPI';
import UseFileInput from './UseFileInputElement';
import { AirLines } from '../../constants/genericTableConstant';

interface IAppendixFileLibraryProps {
  dispatchReducer: React.Dispatch<any>;
  type: string;
}


const AppendixFileLibrary = ({ dispatchReducer, type }: IAppendixFileLibraryProps) => {

  const { userName } = useAppSelector(selectAuth);
  const { appendixFiles } = useAppSelector(selectSim);
  let localFileList = appendixFiles;
  const [displayDate, setDisplayDate] = useState(moment().format("YYYY/MM/DD"));
  const [refreshKey, setRefreshKey] = useState(false);
  
  //Filter criteria
  const [ fileName, setFileName] = useState("");
  const [ uploadDate, setUploadDate] = useState("");
  const [fileList, setFileList] = useState<any[]>([]);

  const { fileInputRef, triggerFileSelect, clearFileValue } = UseFileInput();

  const onUploadFromLocal = () => {
    triggerFileSelect();
  }

  const selectFile = async (e) => {
    const isHKE = (type === AirLines.HKE);
    await uploadAppendixFile(e, userName,localFileList, isHKE);
    setRefreshKey(true);
    clearFileValue();
  };

  useEffect(() => {
    setRefreshKey(false);
    const mockMap = new Map<string, string>();
    if (type === AirLines.HKE) {
      getHKEAppendixFiles({}).then((res) => {
        res.forEach(file => {
          mockMap.set(file.pdfName, file.uploadDate);
        });
        localFileList = Object.values(res || []);
        setFileList(localFileList);
      });
    } else {
      getAppendixFiles({}).then((res) => {
        res.forEach(file => {
          mockMap.set(file.pdfName, file.uploadDate);
        });
        localFileList = Object.values(res || []);
        setFileList(localFileList);
      });
    }
  }, [refreshKey]);

  const onSearchFiles = () => {
    if(fileName === "" && uploadDate === ""){
      setFileList(localFileList);
    }
    let filteredFileList: {
      pdfName: string,
      uploadDate: string
    }[] = [...localFileList];
    if(fileName && fileName != ""){
      const lowerCaseFileName = fileName.toLowerCase();
      filteredFileList = filteredFileList.filter(file => file.pdfName.toLowerCase().includes(lowerCaseFileName));
    }
    if(uploadDate && uploadDate != ""){
      filteredFileList = filteredFileList.filter(file => (file.uploadDate.split('T')[0]).includes(uploadDate.split('T')[0]));
    }
    setFileList(filteredFileList);
  }

  useEffect(()=>{
    setFileList(localFileList);
  },[localFileList])

  const UploadAppendixFileButton = () => {
    return <CXButton 
      onClick={() => onUploadFromLocal()} 
      text={"Upload New file"}
      outline={true} 
      fontSize={"14px"} />
  };

  return (<>
    <div className={"justify-content-between"} >
        <CRow className={"p-2 ps-0"}>
            <CCol className="snnKeyword" sm>
              <CXTextInput placeholder={"File name"} value={fileName} onChange={setFileName} zIndex={1029} />
            </CCol>
            <CCol className="snnKeyword" sm>
              <CXDatePicker placeholder={"Upload date"} value={uploadDate}  onChange={setUploadDate} hasDefault={false} zIndex={1029} />
            </CCol>
            <CCol sm>
              <CXButton outline={false} text={"Search"} onClick={() => {onSearchFiles()}} fontSize={"14px"} />
            </CCol>
            <CCol sm style={{textAlign: "right"}}>
              <AddOrEditOFPLightBox TriggerButton={UploadAppendixFileButton()} heading={"Upload New file"} submitButtonText={"Upload New file"}/>
              <input ref={fileInputRef} onChange={selectFile} type="file" hidden multiple></input>
            </CCol>
        </CRow>
        </div>
        <div className={"section appendixTable"}>
          <AppendixFilesTable 
            appendixFiles={fileList || []} 
            dispatchReducer={dispatchReducer}
            type={type}
            deleteFileRefresh={setRefreshKey}/>
        </div>
    </>)
};

export default AppendixFileLibrary;